import React, { useState } from 'react'

import { graphql } from 'gatsby'
import styled from 'styled-components'
// import Fade from 'react-reveal/Fade'
import { Fade } from 'react-awesome-reveal'
import { motion } from 'framer-motion'

// Import used image materiail
import HafsaProfilePictureBlackOutline from '@images/stories/2021/rohingya/hafsa-profile-picture-black-outline-retina.png'
import HafsaProfilePictureWhiteOutline from '@images/stories/2021/rohingya/hafsa-profile-picture-white-outline-retina.png'
import RomidaProfilePictureBlackOutline from '@images/stories/2021/rohingya/romida-profile-picture-black-outline-retina.png'
import RomidaProfilePictureWhiteOutline from '@images/stories/2021/rohingya/romida-profile-picture-white-outline-retina.png'
import CaptionSpeechPointer from '@images/stories/2021/rohingya/speech-pointer.png'
import CaptionSpeechPointerDownRight from '@images/stories/2021/rohingya/speech-pointer-down-right.png'
import CaptionSpeechPointerUpRight from '@images/stories/2021/rohingya/speech-pointer-up-right.png'
import CaptionSpeechPointerUpRightLarge from '@images/stories/2021/rohingya/speech-pointer-up-right-large.png'
import HafsaPullQuoteProfile from '@images/stories/2021/rohingya/hafsa-pull-quote-profile.png'
import RomidaPullQuoteProfile from '@images/stories/2021/rohingya/romida-pull-quote-profile.png'
import CarryingContainersPortrait from '@images/stories/2021/rohingya/carrying-containers-portrait.jpg'
import CarryingContainers from '@images/stories/2021/rohingya/carrying-containers.png'
import HandsBackground from '@images/stories/2021/rohingya/hand-background.jpg'
import DadHand from '@images/stories/2021/rohingya/dad-hand.png'
import HafsaHand from '@images/stories/2021/rohingya/h-hand.png'

// Import used video material
import DroneFootageMP4 from '@images/stories/2021/rohingya/drone-footage.mp4'
import DroneFootageWEBM from '@images/stories/2021/rohingya/drone-footage.webm'
import SatelliteFootage from '@images/stories/2021/rohingya/satellite-gif-compressed.mp4'
import BRollFootage from '@images/stories/2021/rohingya/BRoll-no-tone-match-yes-fade-yes-colour-correct.mp4'

import { Waypoint } from 'react-waypoint'
import { GatsbyImage } from 'gatsby-plugin-image'

// The project specific components
import AudioPlayer from '@components/scrollytelling/audioplayer'
import Layout from '@components/common/layout'

import '@styles/rohingya.scss'

const ScrollToTop = styled.a`
	position: relative;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 999;
	bottom: 0;

	&:hover {
		background: transparent !important;
	}

	button {
		position: relative;
		background-size: cover;
		background-image: url('https://www.thenewhumanitarian.org/sites/all/themes/thenewhumanitarian/images/icon-return.svg');
	}
`

const AudioPLayerContainer = styled.div`
	width: 100%;
	margin: 5rem auto 2rem auto;
	max-width: 700px;
`

const ArticleTags = () => {
	return (
		<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row items-center w-full mb-4 mx-auto justify-center'}>
			<a
				href='https://www.thenewhumanitarian.org/special-report'
				title='View more articles of type Special Report'
				className='mr-4 font-bold byline text-gray-50 hover:bg-transparent'
			>
				Special Report
			</a>

			<span className={'text-gray-400'}>21 December 2021</span>
		</div>
	)
}

const RohingyaStory = (props) => {
	const [eyesTransitioned, setEyesTransitioned] = useState(false)
	const [tentsTransitioned, setTentsTransitioned] = useState(false)
	const [fleeingTransitioned, setFleeingTransitioned] = useState(false)
	const [handsTransitioned, setHandsTransitioned] = useState(false)

	const Images = props.data.allFile.edges

	const getImageByName = (name) => {
		return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
	}

	return (
		<Layout
			inverted
			bgColor={'bg-black'}
			shareProps={{
				title: 'The New Humanitarian | Rohingya comics: The women refugees pushing for change',
				url: 'https://www.thenewhumanitarian.org/2021/12/21/Bangladesh-Rohinyga-camp-women-illustration',
				socialTitle: '‘I am a leader of my house’: An illustrated story',
				socialDescription:
					'These women are defying community expectations to push for change in the Rohingya refugee camps – and for the chance to return home.',
				socialImage: 'https://media.thenewhumanitarian.org/teaser-rohingya-comics-small.jpg',
				nodeId: 262253,
				updatedTime: '2021-12-21T10:00:00+00:00',
				modiefiedTime: '2021-12-21T10:00:00+00:00',
				publishedTime: '2021-12-21T10:00:00+00:00',
			}}
		>
			{/* Start of Rohingya Comic */}

			<div className={'rohingya-container'}>
				<div
					id='start'
				/>

				<div
					className='rohingya-section hero-section section-background-image-container'
					style={{
						position: 'relative',
						width: '100%',
						paddingBottom: 'calc(56.25% + 8rem)',
						zIndex: 55,
					}}
				>
					<div className='rohingya-section-background-image'>
						<GatsbyImage image={getImageByName('intro-header-with-fade')} className='hide-on-mobile' alt={'Desktop intro image'} />
						<GatsbyImage image={getImageByName('Title-A1 1')} className='hide-on-non-mobile absolute w-full h-full' alt={'Mobile intro image'} />
					</div>
					<div className='hero-text'>
						<h1 className='hero-title'>‘I am a leader of my house’</h1>
						<p className='hero-text-paragraph'>
							How Romida and Hafsa are pushing for change in the Rohingya refugee camps – while holding on to the hope of returning home.{' '}
						</p>
					</div>
				</div>

				<div>
					<section>
						<ArticleTags />
						<div className='rohingya-wrapper'>
							<p className='sub-title text-centered'>
								COX’S BAZAR, Bangladesh
								<br />
							</p>
							<AudioPLayerContainer>
								<AudioPlayer src={`https://media.thenewhumanitarian.org/Rohingya-comics-women.mp3`} />

								<p className='sub-title text-centered'>Listen to this story in the Rohingya language</p>
							</AudioPLayerContainer>
						</div>
					</section>
					<Fade>
						<div className='rohingya-wrapper'>
							<video
								playsInline
								autoPlay
								muted
								loop
								width='100%'
								title='Drone footage on loop: sprawling refugee camp seen from above, camera slowly drops revealing a group of children playing'
							>
								<source src={DroneFootageMP4} type='video/mp4' />
								<source src={DroneFootageWEBM} type='video/webm' />
								{/* <source src={DroneFootageOGG} type='video/ogg' /> */}
								Sorry, your browser doesn't support embedded videos.
							</video>
							<p className='narration-large narration-large-1' style={{ marginTop: '100px' }}>
								Rohingya refugees have lived in Bangladesh for decades. They fled persecution in Myanmar, including a violent military purge that pushed out
								hundreds of thousands of people in 2017. As Romida and Hafsa found when they arrived, life in these cramped camps is hard, especially for women
								and children.
							</p>
						</div>
					</Fade>
				</div>

				<Fade>
					<section>
						<div className='rohingya-wrapper'>
							<div className='rohingya-section tag' style={{ position: 'relative' }}>
								<div style={{ width: '100%', maxWidth: '770px' }}>
									<GatsbyImage
										image={getImageByName('walking-by-the-market')}
										alt='Woman walking through the refugee camp during the daytime, passing shops and waving and smiling at others'
									/>
								</div>

								<div
									className='caption-wrapper tag'
									style={{
										position: 'absolute',
										right: '3%',
										top: '55%',
										width: '570px',
									}}
								>
									<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
										<img
											loading='lazy'
											alt='Hafsa'
											className='profile-picture'
											src={HafsaProfilePictureBlackOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											loading='lazy'
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												left: '27%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className={'rohingya-paragraph'}>
											Hafsa: “In Myanmar, women used to live with dignity, but in the camps they are always worried about their safety. Women don’t feel safe
											anymore – even at home.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<div>
					<div className='rohingya-wrapper daily-stuggles-section' style={{ padding: '100px 0 0' }}>
						<p className='narration narration--daily-struggles' style={{ width: '50%', maxWidth: '570px', marginBottom: '50px' }}>
							Women and girls live with daily struggles most men don’t have to think about: domestic violence, family restrictions, pressure or even threats
							from men who don’t think women should work outside the home …
						</p>
						<p
							className='narration narration--daily-struggles'
							style={{
								width: '50%',
								maxWidth: '570px',
								textAlign: 'center',
								float: 'right',
							}}
						>
							… or simply the dangers of using a toilet at night.
						</p>
					</div>
					<Fade>
						<section className='lamp-and-eyes-section'>
							<div className='rohingya-wrapper'>
								<div className='rohingya-section tag' style={{ position: 'relative' }}>
									<GatsbyImage
										image={getImageByName('lamp-and-eyes-retina')}
										alt='Woman walking at night, holding a lamp. Nothing is visible aside from eyes in the darkness - woman is in a hurry and fearful'
										className='lamp-and-eyes-image'
									/>
									<div
										className='caption-wrapper tag'
										style={{
											position: 'absolute',
											left: '0%',
											top: '25%',
											width: '570px',
										}}
									>
										<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
											<img
												loading='lazy'
												alt='Romida'
												className='profile-picture'
												src={RomidaProfilePictureBlackOutline}
												style={{
													position: 'absolute',
													top: 0,
													left: '35px',
													transform: 'translateY(-75%)',
												}}
											/>
											<img
												loading='lazy'
												alt=''
												className='caption-speech-pointer'
												src={CaptionSpeechPointer}
												style={{
													position: 'absolute',
													top: '0px',
													left: '27%',
													transform: 'translateY(-34px)',
												}}
											/>
											<p className={'rohingya-paragraph'}>
												Romida: “We have one toilet for 10 or 20 houses. It’s difficult for unmarried women to go far from their homes to use the toilet. They
												need to pass groups of men on the way, which is pretty uncomfortable.{' '}
												<span className='emphasis'>We fear being attacked when it's dark.</span> There are many problems, especially for women. Domestic
												violence is a big one, and the fact that there are no jobs for the men – or women – is a huge cause of this.”
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>
					</Fade>
				</div>

				{/* <Fade> */}
				<div className='rohingya-section hafsa-romida-intro-section tag' style={{ position: 'relative' }}>
					<p
						style={{
							zIndex: 1,
							width: '460px',
							margin: '0 auto',
							position: 'absolute',
							top: '50%',
							marginTop: '-50px',
							left: '50%',
							marginLeft: '-230px',
						}}
						className='narration--black caption-hafsa-romida-intro text-centered'
					>
						Hafsa and Romida want things to be different. They're pushing for better education for Rohingya kids, and helping to give women and girls a voice in
						the way the camps function.
					</p>
					<GatsbyImage
						image={getImageByName('Rectangle 209')}
						alt='Close up portraits of Romida and Hafsa on a blue background, Hafsa holding a stack of books'
						className='hide-on-mobile'
					/>
					<GatsbyImage image={getImageByName('portrait-1')} alt='Portrait of Romida' className='hide-on-non-mobile' />
					<div className='caption caption-romida-intro'>
						<img
							loading='lazy'
							alt=''
							className='caption-speech-pointer'
							src={CaptionSpeechPointer}
							style={{
								position: 'absolute',
								top: '0px',
								left: '47%',
								transform: 'translateY(-34px)',
							}}
						/>
						<h3>Romida, 29</h3>
						<h4>Elected Community Leader</h4>
						<p className='rohingya-paragraph'>“I want to keep the women in my camp safe.”</p>
					</div>
					<GatsbyImage image={getImageByName('portrait-2')} alt='Portrait of Hafsa' className='hide-on-non-mobile' />
					<div className='caption caption-hafsa-intro'>
						<img
							loading='lazy'
							alt=''
							className='caption-speech-pointer caption-speech-pointer-down-right'
							src={CaptionSpeechPointerDownRight}
							style={{
								position: 'absolute',
								bottom: '0px',
								right: '47%',
								transform: 'translateY(100%)',
							}}
						/>
						<img
							loading='lazy'
							alt=''
							className='caption-speech-pointer caption-speech-pointer-up-right'
							src={CaptionSpeechPointerUpRight}
							style={{
								position: 'absolute',
								top: '0px',
								left: '30%',
								transform: 'translateY(-34px)',
							}}
						/>
						<h3>Hafsa, 22</h3>
						<h4>University student and aspiring teacher and social activist</h4>
						<p className='rohingya-paragraph'>“I want to ensure graduation for every Rohingya child out there.”</p>
					</div>
				</div>
				{/* </Fade> */}
				<Fade>
					<div className='rohingya-wrapper elected-official-section'>
						<div className='narration-overlap-wrapper'>
							<div
								className='caption-wrapper'
								style={{
									paddingTop: 0,
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									maxWidth: '570px',
									background: 'black',
									zIndex: 1,
								}}
							>
								<p className='narration' style={{ padding: '20px' }}>
									As an elected leader, Romida represents some 16,000 people in one part of the massive camps. She helps resolve family disputes, coaches people
									on how to prepare for disasters or how to stop child abuse, and tries to make sure things like damaged homes and roads get fixed. In a
									community often dominated by conservative men, she stands out.
								</p>
							</div>
							<p
								className='narration narration-overlap-box narration-overlap-box-1'
								style={{
									width: '400px',
									top: '70%',
									left: '52%',
									padding: '15px',
									zIndex: '1',
								}}
							>
								… But some of these men don’t like having a woman in charge.
							</p>
							<GatsbyImage
								image={getImageByName('Rectangle 215')}
								alt='Romida resolving a dispute between two women, in the distance three men looking on disapprovingly'
								style={{ top: '120px' }}
							/>
							<div
								className='caption-wrapper'
								style={{
									position: 'absolute',
									left: '5%',
									top: '85%',
									width: '570px',
								}}
							>
								<div
									className='caption'
									style={{
										boxSizing: 'border-box',
										padding: '20px',
										position: 'relative',
									}}
								>
									<img
										alt='Romida'
										className='profile-picture'
										src={RomidaProfilePictureWhiteOutline}
										style={{
											position: 'absolute',
											top: 0,
											left: '35px',
											transform: 'translateY(-75%)',
										}}
									/>
									<img
										alt=''
										className='caption-speech-pointer'
										src={CaptionSpeechPointer}
										style={{
											position: 'absolute',
											top: '0px',
											left: '30%',
											transform: 'translateY(-34px)',
										}}
									/>
									<p className='rohingya-paragraph'>
										<span className='emphasis'>“We need women in leadership roles to demand this change that we need.</span> Women are happy that they can share
										problems with me as a lady leader. They are happy to have me, but I don’t know about guys. Majhis* are often not happy to see me as a
										leader. Ultimately, I want to keep the women in my camp safe: I want to ensure every woman's safety.”
									</p>
								</div>
							</div>
							<p
								className='narration narration-overlap-box narration-overlap-box-2'
								style={{
									fontSize: '14px',
									width: '400px',
									top: '120%',
									left: '60%',
									padding: '15px',
								}}
							>
								*unelected Rohingya representatives installed by the Bangladesh army.
							</p>
						</div>
					</div>
				</Fade>

				<Fade>
					<section className='hafsa-at-uni-section'>
						<div className='rohingya-wrapper'>
							<div className='rohingya-section tag clearfixed' style={{ position: 'relative', width: '100%' }}>
								<div
									className='caption-wrapper'
									style={{
										background: 'black',
										width: '500px',
										position: 'absolute',
										left: 0,
										top: 0,
										zIndex: 1,
									}}
								>
									<p className='narration' style={{ padding: '20px' }}>
										Hafsa stands out, too. She goes to university in the nearby city of Chittagong. In Myanmar, few Rohingya ever make it to university due to
										deep discrimination and restrictions that prevent many from studying, going to proper hospitals, or simply leaving their villages without
										permission. Hafsa finished high school but was never allowed to enrol in a university. In Bangladesh, however, she won a rare scholarship
										while volunteering to work on sexual and gender-based violence for a medical NGO. She hopes to major in politics, philosophy, and economics.
									</p>
								</div>
								<div
									className='rohingya-image-wrapper'
									style={{
										maxWidth: '770px',
										float: 'right',
										marginTop: '25%',
										width: '100%',
									}}
								>
									<GatsbyImage
										image={getImageByName('Rectangle 189 (3)')}
										alt='Hafsa sitting in the back of a lecture hall at university, raising her hand to speak'
									/>
								</div>
							</div>
						</div>
					</section>
				</Fade>
				<section className='pull-quote-section'>
					<div className='rohingya-wrapper'>
						<div className='pull-quote-wrapper'>
							<img loading='lazy' alt='Hafsa' src={HafsaPullQuoteProfile} />
							<p className='pull-quote'>
								“Through all of this, I never gave up the dream of studying further. I always wanted to graduate and become a teacher. Eventually, I made my
								dreams a reality.”
							</p>
						</div>
					</div>
				</section>

				<section>
					<div className='rohingya-wrapper' style={{ maxWidth: '770px' }}>
						<p className='narration'>
							Romida and Hafsa see themselves as leaders, but they never planned for this. They both fled their homes in Myanmar’s Rakhine State during 2017’s
							military crackdown. UN investigators and human rights groups say the persecution of the Rohingya in Myanmar is part of a decades-long genocide.
							Government policies and military crackdowns have stripped them of citizenship, thrust apartheid-like restrictions on their daily lives, and
							expelled them from their homeland.
						</p>
					</div>
				</section>

				<Fade>
					<div className='rohingya-section military-crackdown-section' style={{ position: 'relative' }}>
						<Waypoint
							onPositionChange={({ previousPosition, currentPosition, event }) => {
								if (currentPosition === 'below') {
									setFleeingTransitioned(false)
								} else {
									setFleeingTransitioned(true)
								}
							}}
							topOffset={'-100%'}
							bottomOffset={'75%'}
						>
							<div className={fleeingTransitioned === true ? 'fleeing-background-wrapper--transitioned' : ''}>
								<GatsbyImage
									image={getImageByName('Visual 5')}
									alt='A line of people fleeing Myanmar. They fade out as you scroll down'
									className='fleeing-background'
								/>
								<GatsbyImage
									image={getImageByName('romida-isolated')}
									alt='Romida looking back sad and longingly at the line of people towards Myanmar'
									className='military-crackdown-image'
									style={{
										width: '100%',
										zIndex: 0,
										position: 'absolute',
										top: 0,
										left: 0,
									}}
								/>
							</div>
						</Waypoint>
						<div
							className='caption-wrapper tag'
							style={{
								position: 'absolute',
								right: '20%',
								top: '5%',
								width: '570px',
							}}
						>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									className='profile-picture'
									alt='Romida'
									src={RomidaProfilePictureBlackOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '30%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>“The army attacked us and our villages, and we had to leave our homes and country behind.”</p>
							</div>
						</div>
					</div>
				</Fade>

				<div className='rohingya-section circular-image-section' style={{ width: '100%', position: 'relative' }}>
					<GatsbyImage
						image={getImageByName('circular-image')}
						alt='Hafsa riding in a boat in the darkness with dozens of others fleeing Myanmar'
						className='circular-image'
						style={{
							position: 'absolute',
							zIndex: 0,
							transform: 'translate(-50%, -10%)',
							left: '50%',
							width: '45%',
						}}
					/>
					<div className='rohingya-wrapper' style={{ position: 'relative', height: 0 }}>
						<div
							className='caption-wrapper tag'
							style={{
								position: 'absolute',
								left: '0%',
								top: '62.5%',
								width: '570px',
							}}
						>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									className='profile-picture'
									alt='Hafsa'
									src={HafsaProfilePictureBlackOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '30%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>
									“It took 15 days for us to come to Bangladesh by foot and by boat. At one point, I didn’t think that I would make it. My legs were so painful
									I couldn't walk. I told everyone to go without me. Then someone said the army was nearby; we started running. Soon, we heard gunshots. Two of
									our relatives died.”
								</p>
							</div>
						</div>
					</div>
				</div>

				<Fade>
					<section className='seven-hundred-thousand-section'>
						<div className='rohingya-wrapper'>
							<div className='rohingya-grid'>
								<div className='col-half'>
									<GatsbyImage image={getImageByName('Rectangle 189 (1)')} alt='Hafsa and her mother gather their belongings in a rush' />
								</div>
								<div className='col-half'>
									<div className='narration-wrapper'>
										<p className='narration'>
											Within weeks, more than 700,000 people crossed into Bangladesh. Thousands were killed. Families were separated in the chaos.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<Fade>
					<div
						style={{ marginTop: '40px', width: '100%', position: 'relative' }}
						className={handsTransitioned === true ? 'section hands-effect hands-effect--transitioned' : 'section hands-effect'}
					>
						<Waypoint
							onPositionChange={({ previousPosition, currentPosition, event }) => {
								if (currentPosition === 'below') {
									setHandsTransitioned(false)
								} else {
									setHandsTransitioned(true)
								}
							}}
							topOffset={'-100%'}
							bottomOffset={'75%'}
						>
							<div
								className='hands-effect-inner-wrapper'
								style={{
									position: 'relative',
									overflow: 'hidden',
									paddingBottom: '56.25%',
									width: '100%',
								}}
							>
								<img
									src={HandsBackground}
									alt='A dark background'
									className='hand-background'
									style={{ position: 'absolute', width: '100%', zIndex: -1 }}
									loading='eager'
								/>
								<motion.div
									animate={{
										x: handsTransitioned === true ? '30%' : '0',
										opacity: handsTransitioned === true ? 0 : 1,
									}}
									transition={{ duration: 5, ease: 'easeInOut' }}
								>
									<img
										src={DadHand}
										alt="Hafsa's father's hand moving to the right as you scroll"
										className='dad-hand'
										style={{
											position: 'absolute',
											width: '100%',
											zIndex: -1,
											willChange: 'right, opacity',
										}}
										loading='eager'
									/>
								</motion.div>

								<motion.div
									animate={{
										x: handsTransitioned === true ? '-30%' : '0',
										opacity: handsTransitioned === true ? 0 : 1,
									}}
									transition={{ duration: 5, ease: 'easeInOut' }}
								>
									<img
										src={HafsaHand}
										alt="Hafsa's hand moving to the left as you scroll"
										className='h-hand'
										style={{
											position: 'absolute',
											width: '100%',
											zIndex: -1,
											willChange: 'left, opacity',
										}}
										loading='eager'
									/>
								</motion.div>
							</div>
						</Waypoint>
						<div
							className='caption-wrapper no-outer-wrapper'
							style={{
								width: '90%',
								padding: '20px',
								position: 'absolute',
								maxWidth: '570px',
								right: '5%',
								bottom: '0%',
							}}
						>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									alt='Hafsa'
									className='profile-picture'
									src={HafsaProfilePictureBlackOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '30%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>
									“We left in a rush – me, my six siblings, and my mother. My father worked in town and we were living in a village. When the military attacks
									started, we couldn’t get to my father and he couldn’t get to us. Neighbouring areas were also under attack, so we were stuck. My father said
									we should flee to Bangladesh. <span className='emphasis'>So he stayed, and we left…</span>”
								</p>
							</div>
						</div>
					</div>
				</Fade>

				<Fade>
					<div className='rohingya-wrapper satellite-section'>
						<video
							title='Satellite footage from 2016 - 2019, showing the rapid expansion of the span of the refugee camps from above'
							playsInline
							autoPlay
							muted
							loop
							width='100%'
							style={{
								margin: '0px auto 40px',
								width: '100%',
								maxWidth: '570px',
								display: 'block',
							}}
						>
							<source src={SatelliteFootage} type='video/mp4' />
							Sorry, your browser doesn't support embedded videos.
						</video>
						<p className='narration-large narration-large-1 spacing-medium'>
							The 2017 crackdown quadrupled the population of Bangladesh’s sprawling camps. Today, some 900,000 Rohingya live here. The vast majority are women
							or children.
						</p>
					</div>
				</Fade>

				<div className='stacking-context arrival-scene-section' style={{ position: 'relative' }}>
					<Fade>
						<div className='rohingya-section tag' style={{ position: 'relative', zIndex: 1 }}>
							<GatsbyImage
								image={getImageByName('arrival-scene')}
								objectFit={'cover'}
								alt='Hafsa and her family arriving to the camps, alongside a large group of fellow migrants'
							/>

							<div className='rohingya-wrapper'>
								<div className='caption-wrapper' style={{ position: 'absolute', top: '80%', width: '570px' }}>
									<div className='caption' style={{ position: 'relative' }}>
										<img
											alt='Hafsa'
											className='profile-picture'
											src={HafsaProfilePictureBlackOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												left: '30%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“When we arrived at the camps, everything was messy and horrible. There were no toilets, and no way to purify water for drinking.{' '}
										</p>
										<p className='rohingya-paragraph'>
											When the military attacked us, we thought we would return home soon, so we didn’t take much. I took only my mobile phone. My mother took
											my education certificates and our family documents.
										</p>
										<p className='rohingya-paragraph'>
											We didn’t have enough clothes to wear. I helped my mother set up a home, helped my siblings with their studies, and started volunteering
											with an NGO. It felt good that I could help people when I was suffering too.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</Fade>

					<Fade>
						<section
							className='sweeping-section'
							style={{
								paddingTop: '220px',
								paddingBottom: 0,
								position: 'relative',
							}}
						>
							{' '}
							{/* z-index -1 needed so caption above overlaps the image in this section */}
							<div className='rohingya-wrapper clearfixed'>
								<div
									className='rohingya-section tag'
									style={{
										position: 'relative',
										width: '100%',
										paddingBottom: '5%',
									}}
								>
									<div
										style={{
											width: '100%',
											float: 'right',
											maxWidth: '770px',
											right: 0,
											zIndex: -1,
										}}
									>
										<GatsbyImage
											image={getImageByName('pot-and-broom')}
											alt='Hafsa cooking in her home in the refugee camp, while her mother cleans and sweeps'
											className='h-hand'
										/>
									</div>
								</div>
							</div>
						</section>
					</Fade>
				</div>

				<Fade>
					<section style={{ paddingTop: '0px' }} className='group-gathered-around-speaker-section'>
						<div className='rohingya-wrapper'>
							<div className='rohingya-section section-background-image-container tag' style={{ position: 'relative' }}>
								<div style={{ maxWidth: '770px', width: '100%' }}>
									<GatsbyImage
										image={getImageByName('group-gathered-around-speaker')}
										alt='Romida looking on to a male leader in the distance, with dozens of others crowded around him'
										className='h-hand'
									/>
								</div>
								<div
									className='caption-wrapper tag'
									style={{
										position: 'absolute',
										left: '50%',
										bottom: '0%',
										width: '570px',
										transform: 'translate(-50%, 45%)',
									}}
								>
									<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
										<img
											alt='Romida'
											className='profile-picture'
											src={RomidaProfilePictureBlackOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												left: '27%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“As women, living in the camp is not easy. We face more problems than men. Law enforcement and other support systems were not in place at
											the start. So a female leader was needed back then – someone who can listen to women, understand, and speak on behalf of them.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<Fade>
					<div className='rohingya-section carrying-containers-section' style={{ width: '100%', position: 'relative' }}>
						<div className='rohingya-wrapper'>
							<p className='narration-large'>
								The camps are run by Bangladesh’s government, and aid is provided by dozens of local and international agencies. Almost all Rohingya depend on
								humanitarian aid to survive – food rations, water, healthcare, schools. But it’s not enough for people to thrive.
							</p>
							<p className='narration text-centered'>That’s what women like Romida and Hafsa want to change.</p>
							<picture className='img--full-width'>
								<source media='(max-width: 800px)' srcSet={CarryingContainersPortrait} />
								<source srcSet={CarryingContainers} />
								<img
									style={{ marginTop: '60px' }}
									className='img--full-width'
									src={CarryingContainers}
									alt='Romida walking through the camp holding water jugs, passing international NGO signs along the way'
									loading='lazy'
								/>
							</picture>
							<div className='caption-wrapper' style={{ paddingTop: 0 }}>
								<div
									className='caption'
									style={{
										position: 'relative',
										maxWidth: '570px',
										transform: 'translate(40px, -60%)',
									}}
								>
									<img
										alt='Hafsa'
										className='profile-picture'
										src={HafsaProfilePictureWhiteOutline}
										style={{
											position: 'absolute',
											top: 0,
											left: '35px',
											transform: 'translateY(-75%)',
										}}
									/>
									<img
										alt=''
										className='caption-speech-pointer'
										src={CaptionSpeechPointer}
										style={{
											position: 'absolute',
											top: '0px',
											left: '30%',
											transform: 'translateY(-34px)',
										}}
									/>
									<p className='rohingya-paragraph'>
										“All the aid services are available in the camps now, but the quality has to be improved. NGOs are just trying to do their jobs, but it’s
										not enough. Things like access to education, child care, and maternal health need to be improved.”
									</p>
								</div>
							</div>
						</div>
					</div>
				</Fade>

				{/* <Fade> */}
				<div>
					<section>
						<div className='rohingya-wrapper'>
							<p className='narration text-centered'>Sometimes, there’s not enough water and food… </p>
						</div>
					</section>
					<div className='rohingya-section tag queue-section' style={{ position: 'relative' }}>
						<div className='caption-wrapper long-queues-caption-wrapper no-outer-wrapper' style={{}}>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									alt='Romida'
									className='profile-picture'
									src={RomidaProfilePictureWhiteOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '40%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>
									“In order to use health services, people have to wait in long queues. Often, they are only given paracetamol if they are sick. Those who have
									enough money can pay for better treatment outside the camp.”
								</p>
							</div>
						</div>
						<GatsbyImage
							image={getImageByName('long-queue')}
							alt='Long queues of people stemming from the food and healthcare facilities, people gathering water with jugs from a stream'
						/>
						<div className='caption-wrapper no-outer-wrapper not-enough-food-caption-wrapper' style={{}}>
							<div
								className='caption'
								style={{
									position: 'relative',
									transform: 'translate(40px, -100px)',
								}}
							>
								<img
									alt='Romida'
									className='profile-picture'
									src={RomidaProfilePictureWhiteOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '30%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragaph'>
									“Food is provided by the World Food Programme, but it is not enough. We are given 20 litres of water per person every day – it takes two
									litres just to use the toilet. We have to collect water in groups at night or in the morning. We can only fill three or four water jars at a
									time, which is not enough for a family. There's no other way to collect clean water. There’s a little lake here and the water is very dirty.
									But sometimes people collect water from there as well when they need more.”
								</p>
							</div>
						</div>
					</div>
					<section>
						<div className='rohingya-wrapper' style={{ padding: '40px 0' }}>
							<p className='narration text-centered'>… and tiny tent homes are fragile and cramped. </p>
						</div>
					</section>
				</div>
				{/* </Fade> */}

				<Fade>
					<div className='rohingya-section section-background-image-container tag tents-section' style={{ position: 'relative', width: '100%' }}>
						<Waypoint
							onPositionChange={({ previousPosition, currentPosition, event }) => {
								// console.log(currentPosition)
								if (currentPosition === 'below') {
									setTentsTransitioned(false)
								} else {
									setTentsTransitioned(true)
								}
							}}
							topOffset={'-100%'}
							bottomOffset={'75%'}
						>
							<div id='tents-effect' className={tentsTransitioned === true ? 'tents-effect--transitioned' : ''}>
								<div className='rohingya-section-background-image'>
									<GatsbyImage image={getImageByName('tents-light')} alt='Closely packed tents and people scattered around, as you scroll it turns to night' />
								</div>
								<div className='rohingya-section-background-image tents-dark'>
									<GatsbyImage
										image={getImageByName('tents-dark')}
										alt='The same tents at night - people disappear and only a few houses lit'
										style={{ opacity: 0 }}
									/>
								</div>
							</div>
						</Waypoint>
						<div
							className='caption-wrapper no-outer-wrapper'
							style={{
								width: '570px',
								position: 'absolute',
								right: '10%',
								bottom: '0%',
							}}
						>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									alt='Romida'
									className='profile-picture'
									src={RomidaProfilePictureBlackOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '30%',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>
									“The biggest problem living inside the camps is our living space. It’s very small for us, 5-12 people are sharing a house. The houses are made
									of bamboo wrapped with plastic. We get only 60 bamboo pieces and one piece of plastic per family to build a house. In summer, it gets very
									hot. In winter, due to fog, the plastic portion catches water and our beds get wet. We don’t have electricity at our camp houses. NGOs
									provided us with rechargeable lights, but they don't last through the night. People who have enough money and jobs can buy solar panels and
									batteries.”
								</p>
							</div>
						</div>
					</div>
				</Fade>

				<Fade>
					<section className='blackboard-section'>
						<div className='rohingya-wrapper' style={{ paddingBottom: '25%' }}>
							<div className='narration-overlap-wrapper'>
								<p className='narration narration-overlap-box' style={{ width: '570px', zIndex: '1' }}>
									But the biggest worry is the future. There is no formal schooling for most Rohingya, especially teenagers. There are NGO-run classes teaching
									basic lessons to younger children, but these were shut through most of the COVID-19 pandemic.
								</p>
								<GatsbyImage
									image={getImageByName('blackboard')}
									alt='A classroom with a few young students, teacher pointing to a whiteboard. Older children in background playing football'
								/>
								<div
									className='caption-wrapper caption-grid-1'
									style={{
										width: '570px',
										position: 'absolute',
										left: '50%',
										top: '100%',
									}}
								>
									<div
										className='caption'
										style={{
											position: 'relative',
											boxSizing: 'border-box',
											padding: '20px',
											transform: 'translateX(-50%)',
										}}
									>
										<img
											alt='Romida'
											className='hide-on-mobile profile-picture profile-picture-romida'
											src={RomidaPullQuoteProfile}
											style={{
												position: 'absolute',
												top: '-200px',
												left: '-220px',
											}}
										/>
										<img
											alt='Hafsa'
											className='hide-on-mobile profile-picture profile-picture-hafsa'
											loading='lazy'
											src={HafsaPullQuoteProfile}
											style={{
												position: 'absolute',
												top: '-200px',
												right: '-220px',
											}}
										/>
										<img alt='' className='caption-speech-pointer caption-speech-pointer-up-left' src={CaptionSpeechPointer} />
										<img alt='' className='caption-speech-pointer caption-speech-pointer-up-right' src={CaptionSpeechPointerUpRight} />
										<p className='rohingya-paragraph'>“Education is a crying need.” </p>
									</div>
								</div>
								<div
									className='caption-wrapper caption-grid-2'
									style={{
										margin: '20px',
										width: 'calc(50% - 40px)',
										position: 'absolute',
										left: '0%',
										top: '120%',
									}}
								>
									<div
										className='caption'
										style={{
											boxSizing: 'border-box',
											padding: '20px',
											position: 'relative',
										}}
									>
										<img
											alt='Romida'
											className='hide-on-non-mobile profile-picture'
											src={RomidaProfilePictureWhiteOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointerUpRight}
											style={{
												position: 'absolute',
												top: '0px',
												left: '30%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“There is no education system for young women – only for children under 14. Some women can't even write their names. They can’t read
											signboards. If we can get an education, I believe we won’t be victims like we are now.”
										</p>
									</div>
								</div>
								<div
									className='caption-wrapper caption-grid-3'
									style={{
										margin: '20px',
										position: 'absolute',
										width: 'calc(50% - 40px)',
										left: '50%',
										top: '120%',
									}}
								>
									<div
										className='caption'
										style={{
											boxSizing: 'border-box',
											padding: '20px',
											position: 'relative',
										}}
									>
										<img
											alt='Hafsa'
											className='hide-on-non-mobile profile-picture'
											src={HafsaProfilePictureWhiteOutline}
											style={{
												position: 'absolute',
												top: 0,
												right: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												right: '30%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“There is primary level school, which is good, but what about higher studies after passing the primary level? There’s no scope for high
											school studies. If you want to make a society strong, education is most important. If there’s no education, people won’t be able to
											understand the services provided by NGOs.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<Fade>
					<section className='hafsa-worries-section'>
						<div className='rohingya-wrapper'>
							<p className='narration text-centered spacing-medium' style={{ maxWidth: '770px', width: '90%', margin: '0 auto' }}>
								Hafsa worries about what will happen to Rohingya children without a proper education. She wants to become a teacher, and to be a voice for the
								youngest generation.
							</p>
							<div className='caption-wrapper' style={{ width: '570px', margin: '40px auto' }}>
								<div className='caption' style={{ position: 'relative', boxSizing: 'border-box' }}>
									<img
										alt='Hafsa'
										className='profile-picture'
										src={HafsaProfilePictureBlackOutline}
										style={{
											position: 'absolute',
											top: 0,
											left: '50%',
											transform: 'translate(-50%, -75%)',
										}}
									/>
									<p className='rohingya-paragraph' style={{ paddingTop: '10px' }}>
										“To me, the biggest problem for my community is education.{' '}
										<span className='emphasis'>If you see the total population of the Rohingya community, most of them are children.</span> My younger siblings
										are studying back in the camp with the help of a private tutor, who our family pays. But there is no institutional support for higher
										education. Not everyone is able to spend money for private education, and not everyone has permission from home to attend the NGO schools."
									</p>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<div>
					<section className='pull-quote-section education-for-everyone-pull-quote-section'>
						<div className='rohingya-wrapper'>
							<div className='pull-quote-wrapper'>
								<img alt='Hafsa' src={HafsaPullQuoteProfile} />
								<p className='pull-quote text-centered'>“If there's education for everyone, every other problem will begin to find its solution.”</p>
							</div>
						</div>
					</section>
					<section className='stats-section section--white' style={{ background: 'white' }}>
						<div className='rohingya-wrapper'>
							<p className='narration narration--black'>Rohingya camps: Half the population are 17 or younger</p>
							<div className='age-distribution-illustration-image-wrapper' style={{ position: 'relative' }}>
								<GatsbyImage
									image={getImageByName('age-distribution-illustration')}
									alt='Illustrated infographic showing the age distribution of people in Rohingya camps.'
									className='camp-age-distribution-image'
								/>
								<div className='infographic-box handwritten-font infographic-box-young-children'>
									<span className='hide-on-mobile'>15% 4 years old and under</span>
									<span className='hide-on-non-mobile'>*</span>
								</div>
								<div className='infographic-box handwritten-font infographic-box-children'>51% children</div>
								<div className='infographic-box handwritten-font infographic-box-adults'>49% Adults</div>
							</div>
							<p className='narration hide-on-non-mobile narration--black'>*15% 4 years old and under</p>
							<p className='narration narration--black no-margin-bottom'>Global average:</p>
							<GatsbyImage
								image={getImageByName('global-average-age-distribution')}
								alt='Illustrated infographic showing the age distribution of people globally.'
							/>
							<div className='rohingya-wrapper clearfixed' style={{ width: '100%', position: 'relative' }}>
								<p
									className='narration narration-legend narration-legend--children handwritten-font narration--black'
									style={{ position: 'absolute', left: '0%' }}
								>
									30% Children
								</p>
								<p
									className='narration narration-legend narration-legend--adults handwritten-font narration--black'
									style={{ position: 'absolute', left: '30%' }}
								>
									70% Adults
								</p>
								<p className='narration narration-legend narration-legend--sources narration--black handwritten-font'>
									<span>
										Sources:{' '}
										<a target='_blank' rel='noreferrer noopener' className={'link-no-bold'} href='https://data2.unhcr.org/en/documents/details/85032'>
											{' '}
											UNHCR
										</a>
										,
									</span>{' '}
									<span>
										<a
											target='_blank'
											rel='noreferrer noopener '
											className={'link-no-bold'}
											href='https://data.unicef.org/resources/state-worlds-children-2016-statistical-tables/'
										>
											{' '}
											UNICEF
										</a>
									</span>
								</p>
							</div>
						</div>
					</section>
				</div>

				<Fade>
					<div>
						<div className='rohingya-section tag scales-section' style={{ position: 'relative' }}>
							<div
								className='caption-wrapper'
								style={{
									background: 'black',
									position: 'absolute',
									top: 0,
									right: '10%',
									width: '100%',
									maxWidth: '570px',
									paddingTop: '20px',
									zIndex: 1,
								}}
							>
								<p className='narration'>
									Women like Romida and Hafsa often clash with their community’s expectations. Rohingya society can be conservative. Women are usually expected
									to stay home – and not be active in public life. Hafsa wants this to change.
								</p>
							</div>
							<GatsbyImage
								image={getImageByName('scales-illuatration')}
								alt='Woman depicted as Lady Justice with scale. One side household items: food, light and a pillow. On the other side: 3 children'
							/>
						</div>
						<section className='pull-quote-section'>
							<div className='rohingya-wrapper'>
								<div className='pull-quote-wrapper'>
									<img alt='Hafsa' src={HafsaPullQuoteProfile} />
									<p className='pull-quote'>
										“Because of my education, I see myself as a leader in my community. I believe a woman is a born leader. I see my mother managing a house,
										which is similar to governmental work: It’s like managing a country.{' '}
										<span className='emphasis'>I am a leader of my house, even if society doesn’t accept me as a leader.</span>”
									</p>
								</div>
							</div>
						</section>
					</div>
				</Fade>

				<Fade>
					<Waypoint
						onPositionChange={({ previousPosition, currentPosition, event }) => {
							if (currentPosition === 'below') {
								setEyesTransitioned(false)
							} else {
								setEyesTransitioned(true)
							}
						}}
						topOffset={'-100%'}
						bottomOffset={'75%'}
					>
						<div className='rohingya-section tag eyes-in-background-section' style={{ position: 'relative' }}>
							<div
								className='caption-wrapper outspoken-leader-caption-wrapper'
								style={{
									background: 'black',
									position: 'absolute',
									top: 0,
									right: '10%',
									width: '100%',
									maxWidth: '570px',
									paddingTop: '20px',
									zIndex: 2,
								}}
							>
								<p className='narration'>
									As an outspoken leader, Romida fears she could become a target. Many Rohingya say the camps have grown unsafe – especially at night.
									Conservative groups in the community are pressuring women not to work or volunteer.
								</p>
							</div>
							<div
								className={
									eyesTransitioned === true
										? 'eyes-in-background-section-wrapper eyes-in-background-section-wrapper--transitioned'
										: 'eyes-in-background-section-wrapper'
								}
								style={{
									position: 'relative',
									height: 0,
									paddingBottom: '56.25%',
									overflow: 'hidden',
								}}
							>
								<GatsbyImage
									image={getImageByName('background-eyes')}
									alt='Eyes on a dark background behind Romida. As you scroll the eyes come into focus'
									className='background-eyes'
									objectFit={'cover'}
								/>

								<div style={{ position: 'absolute', width: '100%', top: 0, height: '100%', left: 0 }}>
									<GatsbyImage
										image={getImageByName('romida-profile-foreground')}
										alt='Portrait of Romida'
										className='foreground-romida'
										style={{ transformOrigin: 'bottom' }}
									/>
								</div>
							</div>
							<div className='caption-wrapper speech-caption-wrapper no-outer-wrapper' style={{ width: '570px', position: 'absolute' }}>
								<div className='caption' style={{ position: 'relative' }}>
									<img
										alt=''
										className='caption-speech-pointer caption-speech-pointer-up-right-large'
										src={CaptionSpeechPointerUpRightLarge}
										style={{
											position: 'absolute',
											top: '0px',
											left: '30%',
											transform: 'translateY(-100%)',
										}}
									/>
									<p className='rohingya-paragraph'>
										“My future is a bit uncertain. For my own security, I don’t want to participate in elections anymore, so I have decided not to stand in
										future ones. I feel like I could be threatened.”
									</p>
								</div>
							</div>
						</div>
					</Waypoint>
				</Fade>

				<Fade>
					<section className='sitting-in-chair-section'>
						<div className='rohingya-wrapper'>
							<p className='narration spacing-medium' style={{ margin: '0 auto', maxWidth: '700px' }}>
								The entire community's future is also uncertain. Few Rohingya have ever been resettled from Bangladesh; some have lived here for decades.
								Rohingya in Myanmar's Rakhine State still face heavy restrictions. Most refugees say it's not safe to return – especially after Myanmar’s
								military seized power in a coup in February 2021. But Romida and Hafsa say Bangladesh’s refugee camps will never feel like home, either.
							</p>
							<div className='rohingya-section tag' style={{ position: 'relative' }}>
								<GatsbyImage
									image={getImageByName('romida-at-desk')}
									alt='Romida in her office sitting on a chair, with papers on her desk, a fan and decorative paper crafts in background'
									style={{ width: '100%', marginTop: '40px' }}
								/>

								<div className='caption-wrapper tag' style={{ position: 'absolute', width: '570px' }}>
									<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
										<img
											alt='Romida'
											className='profile-picture'
											src={RomidaProfilePictureBlackOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												left: '27%',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“Ultimately, I want to make conditions better for our community, but I don’t see any future in Bangladesh.{' '}
											<span className='emphasis'>I am living here like a guest.</span> We had open spaces in Myanmar. When it’s too hot here, I remember how we
											used to enjoy the breeze, sitting in the yard.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Fade>

				<div>
					<Fade>
						<div className='rohingya-wrapper community-section'>
							<div className='col-half'>
								<p className='narration'>As long as she remains in Bangladesh, Hafsa wants to help her community. </p>
								<div className='caption-wrapper' style={{ paddingTop: '40px' }}>
									<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
										<img
											alt='Hafsa'
											className='profile-picture'
											src={HafsaProfilePictureBlackOutline}
											style={{
												position: 'absolute',
												top: 0,
												left: '35px',
												transform: 'translateY(-75%)',
											}}
										/>
										<img
											alt=''
											className='caption-speech-pointer'
											src={CaptionSpeechPointer}
											style={{
												position: 'absolute',
												top: '0px',
												left: '140px',
												transform: 'translateY(-34px)',
											}}
										/>
										<p className='rohingya-paragraph'>
											“There’s no end to learning. I hope to become a social activist who will work for her community. I want to ensure graduation for every
											Rohingya child out there.”
										</p>
									</div>
								</div>
							</div>
						</div>
					</Fade>

					<section className='father-in-hands-section'>
						<div className='rohingya-wrapper'>
							<Fade>
								<div className='rohingya-section tag' style={{ position: 'relative', width: '100%' }}>
									<div className='narration-overlap-box' style={{ position: 'absolute' }}>
										<p className='narration'>
											But her thoughts are still in Myanmar. She remembers how her father used to help her with the housework, even though it was her job, and
											how he prepared the children’s beds at night before they slept. She dreams of a day when she can safely return home, re-uniting her
											family.
										</p>
									</div>

									<div className='father-in-hands-image' style={{ width: '100%', maxWidth: '770px', left: 0 }}>
										<GatsbyImage
											image={getImageByName('father-in-hands')}
											alt='A conceptual illustration of Hafsa holding her father in her hand, looking at him with sadness'
										/>
									</div>
								</div>
							</Fade>
							<Fade>
								<div
									className='rohingya-section tag clearfixed'
									style={{
										position: 'relative',
										right: 0,
										width: '100%',
										paddingBottom: '120px',
									}}
								>
									<div className='rohingya-image-wrapper' style={{ maxWidth: '960px', float: 'right', width: '100%' }}>
										<GatsbyImage
											image={getImageByName('father-in-hands-wide-angle')}
											alt='Hafsa still holding her father in her hands, but zoomed out with tent homes in sight'
										/>
									</div>
									<div
										className='caption-wrapper tag'
										style={{
											zIndex: 1,
											position: 'absolute',
											left: '5%',
											top: '60%',
											width: '570px',
										}}
									>
										<div className='caption tag' style={{ position: 'relative', marginTop: '80px' }}>
											<img
												alt=''
												className='caption-speech-pointer'
												src={CaptionSpeechPointerUpRightLarge}
												style={{
													position: 'absolute',
													top: '0px',
													left: '30%',
													transform: 'translateY(-98%)',
												}}
											/>
											<p className='rohingya-paragraph'>“Every second, every moment is a memory now.”</p>
										</div>
									</div>
								</div>
							</Fade>
						</div>
					</section>
				</div>

				<Fade>
					<div className='rohingya-section tag map-section' style={{ position: 'relative' }}>
						<GatsbyImage
							image={getImageByName('bangladesh-myanmar')}
							alt='Hafsa and her father on a drawing of a map, reaching for each other over the Myanmar - Bangladesh border'
						/>

						<div className='map-label map-label-bangladesh'>
							<div className='map-label-inner'>Bangladesh</div>
						</div>
						<div className='map-label map-label-myanmar'>
							<div className='map-label-inner'>Myanmar</div>
						</div>
						<div className='caption-wrapper no-outer-wrapper' style={{ width: '570px', position: 'absolute' }}>
							<div className='caption' style={{ position: 'relative' }}>
								<img
									alt='Hafsa'
									className='profile-picture'
									src={HafsaProfilePictureBlackOutline}
									style={{
										position: 'absolute',
										top: 0,
										left: '35px',
										transform: 'translateY(-75%)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer hide-on-mobile'
									src={CaptionSpeechPointer}
									style={{
										position: 'absolute',
										top: '0px',
										left: '27%',
										transform: 'translateY(-34px)',
									}}
								/>
								<img
									alt=''
									className='caption-speech-pointer hide-on-non-mobile'
									src={CaptionSpeechPointerUpRight}
									style={{
										position: 'absolute',
										top: '0px',
										right: '160px',
										transform: 'translateY(-34px)',
									}}
								/>
								<p className='rohingya-paragraph'>
									“It’s hard to have my father still in Myanmar, but we want him to live there. It makes us feel like we still have a connection with our
									country, like <span className='emphasis'>there’s still a hope to get back home</span>
									.”
								</p>
							</div>
						</div>
					</div>
				</Fade>

				<section className='credits-section'>
					<div className='rohingya-wrapper'>
						<div className='credits-hero-section'>
							<h2 className='hero-title'>Credits</h2>
							<p className='narration narration-white'>This story was produced by The&nbsp;New&nbsp;Humanitarian and PositiveNegatives</p>
						</div>
						<div
							className='video-wrapper'
							style={{
								position: 'relative',
								width: '100%',
								paddingBottom: '56.25%',
							}}
						>
							<video
								title='Short video clip without sound of live footage from refugee camp; people walking, cycling and riding tuk tuks past'
								playsInline
								autoPlay
								muted
								loop
								width='100%'
								style={{ position: 'absolute', top: 0, left: 0 }}
							>
								<source src={BRollFootage} type='video/mp4' />
								Sorry, your browser doesn't support embedded videos.
							</video>
							<div
								className='video-captions'
								style={{
									position: 'absolute',
									bottom: 0,
									left: 0,
									width: '460px',
									padding: '45px 35px',
									pointerEvents: 'none',
								}}
							>
								<p className='video-caption'>
									<span>Research: Sabiha Faiz</span>
									<span>Illustration: Fahmida Azim</span>
									<span>Production: Sara Wong and Irwin Loy</span>
									<span>Logistical support: Shafi Mohammed</span>
									<span>Additional video: Rajib Mohajan</span>
									<span>Satellite imagery: ESA</span>
									<span>Music: Axletree, Chris Zabriskie, Podington Bear, Blue Dot Sessions</span>
									<span>Additional translations: NaTakallam</span>
								</p>
							</div>
						</div>
					</div>
				</section>

				{/* TODO: Link these tiles to the appropriate pages */}

				<section className='footer read-more-section'>
					<div className='rohingya-wrapper'>
						<div className='text-centered'>
							<h2 className='hero-title footer-h2'>Read More</h2>
							<p className='footer-p'>Hear more from Romida, and explore The New Humanitarian's reporting on the Rohingya.</p>
						</div>
						<div className='tiles'>
							<a
								href={'https://www.thenewhumanitarian.org/in-depth/myanmar-rohingya-refugee-crisis-humanitarian-aid-bangladesh'}
								target={'_blank'}
								className='tile'
								rel={'noreferrer noopener'}
							>
								<div className='thumbnail'>
									<GatsbyImage image={getImageByName('tile-1')} alt='No alt text defined' />
								</div>
								<div className='tile-text'>
									<h3>The Rohingya: Tracking the history of today’s refugee crisis</h3>
									<p className='rohingya-paragraph'>A timeline of a humanitarian emergency decades in the making.</p>
								</div>
							</a>
							<a
								href={'https://www.thenewhumanitarian.org/news-feature/2019/08/26/Rohingya-women-refugee-elections'}
								target={'_blank'}
								className='tile'
								rel={'noreferrer noopener'}
							>
								<div className='thumbnail'>
									<GatsbyImage image={getImageByName('tile-2')} alt='No alt text defined' />
								</div>
								<div className='tile-text'>
									<h3>For Rohingya women, refugee elections bring new opportunities – and new problems</h3>
									<p className='rohingya-paragraph'>
										Romida's experiences as an elected leader show the difficulties of upending a male-dominated leadership system in Bangladesh’s camps.
									</p>
								</div>
							</a>
							<a
								href={'https://www.thenewhumanitarian.org/news/2020/09/17/Bangladesh-Rohingya-justice-system-crimes'}
								target={'_blank'}
								className='tile'
								rel={'noreferrer noopener'}
							>
								<div className='thumbnail'>
									<GatsbyImage image={getImageByName('tile-3')} alt='' />
								</div>
								<div className='tile-text'>
									<h3>No courts, no justice for Rohingya women</h3>
									<p className='rohingya-paragraph'>Women and girls are left behind by a patchwork system focused on mediation rather than prosecution.</p>
								</div>
							</a>
						</div>
					</div>
				</section>
			</div>

			{/* End */}

			<div style={{ background: '#F2F2F2' }}>
				<ScrollToTop href='#start'>
					<button className='js-return-to-top site-footer__return-button'>Return to top</button>
				</ScrollToTop>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query RohingyaImages {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg", "webp"] }, relativePath: { glob: "stories/2021/rohingya/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							quality: 60
							layout: FULL_WIDTH
							breakpoints: [320, 750, 1080, 1366, 1920]
							width: 1024
						)
					}
				}
			}
		}
	}
`

export default RohingyaStory
