import React from 'react'

import styled from 'styled-components'

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'

const StyledDiv = styled.div`
  .rhap_container {
    background: transparent;
  }
  .rhap_time {
    color: white;
  }
  div.download {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: white;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`

const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25px'
      height='25px'
      viewBox='0 0 100 100'
    >
      <path
        fill={'#fff'}
        d='M72.082 36.668L54.168 54.582V12.5h-8.336v42.082L27.918 36.668 22.082 42.5 50 70.418 77.918 42.5zM27.082 79.168h45.832V87.5H27.082z'
      ></path>
    </svg>
  )
}

const AudioPlayerComponent = (props) => {
  const { src } = props

  return (
    <StyledDiv>
      <AudioPlayer
        src={src}
        // onPlay={(e) => console.log('onPlay')}
        showJumpControls={false}
        customAdditionalControls={[]}
        customControlsSection={[
          <div className={'download'}>
            <a href={src}>
              <DownloadIcon />
            </a>
          </div>,
          RHAP_UI.ADDITIONAL_CONTROLS,
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.VOLUME_CONTROLS,
        ]}
      />
    </StyledDiv>
  )
}

export default AudioPlayerComponent
